:root {
	--white: #fff;
	--light-gray: #F5F5F5;
	--gray: #727272;
	--gray-dark: #434343;
	--primary: #f87416;
	--secondary: #858796;
	--success: #00A827;
	--info: #36b9cc;
	--warning: #f6c23e;
	--danger: #EA0000;
	--light: #f8f9fc;
	--dark: #222222;
	--form-border-color: #FFE3C9; 
	--bg-light: #FFF9F5;
	--bg-color:#FFFBF8;
	--table-border: #F5F5F5;
	--light-primary: #FFD5B8;
	--border-color: #ECECEC;
	--purple: #9B2B8B;
	--darkblue: #2B449B;
}

*,
::after,
::before {
	box-sizing: border-box
}
html {
	font-family: 'Comfortaa', sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent
}
body {
	font-family: 'Comfortaa', sans-serif !important;
	background: var(--bg-color);
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(255, 162, 105, 0.1);
	background-color: var(--bg-light);
	border-radius: 10px;
}

::-webkit-scrollbar
{
	width: 5px;
	background-color: var(--bg-light);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #FFF;
	background-image: -webkit-gradient(linear,
									   40% 0%,
									   75% 84%,
									   from(#fcb786),
									   to(#fbb888),
									   color-stop(.6,#fcc49c))
}

hr {
	border-top:var(--primary) !important;
	background: var(--primary) !important;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 500 !important;
	color: var(--dark)
}
h2 {font-size: 2.875rem;}
p {
    margin-top: 0;
    margin-bottom: 0.9375rem;
    font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.8 !important;
}
a {
    color: var(--primary) !important;
    text-decoration: none !important;
	transition: all .25s ease-in;
}
a:hover {
	transition: all .25s ease-in;
}
.primary {
	color: var(--primary) !important;
}
.gray {
	color: var(--gray) !important;
}
.small, small {
    font-size: .875em !important;
}
input.form-control {
    border: 1px solid var(--form-border-color) !important;
    height: 50px !important                                                                                                     ;
    border-radius: 8px !important;
	font-size: .875rem !important;
}
select.form-select {
    border: 1px solid var(--form-border-color) !important;
    height: 50px;
    border-radius: 8px !important;
	font-size: .875rem;
}
textarea.form-control {
	border: 1px solid var(--form-border-color) !important;
    border-radius: 8px !important;
	font-size: .875rem !important;
}
.btn {
	height: 50px;
}
button:focus-visible {
	outline: none !important;
  }
.btn-primary {
    background-color: var(--primary) !important;
    color: var(--white) !important;
    border-color: var(--primary) !important;
    border-radius: 8px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
	min-width: 100px;
	transition: 0.5s !important;
}

/* Travis Head
Quality Analyst */
.btn-primary:hover {
	background:  #eb9558 !important;
	transition: 0.5s !important;
}
.btn-outline-primary {
	color: var(--primary) !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
	border-radius: 8px !important;
	border: 1px solid var(--light-primary) !important;
	background: #fff !important;
}
.btn-outline-primary:hover {
	background: var(--light-primary) !important;
	border-color: var(--light-primary) !important;
	color: var(--primary);
}
.btn.btn-outline-light {
    color: var(--white) !important;
    border: 1px solid var(--white) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
a.btn.btn-outline-light.banner-btn:hover {
    color: var(--primary) !important;
}
.btn-check:checked+.btn, .btn.active, .btn.show, :not(.btn-check)+.btn:active {
    color: var(--primary) !important;
    background-color: var(--white) !important;
    border-color: var(--form-border-color) !important;
}
ul.sort-btn-group {
    list-style: none;
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
    border: 1px solid var(--light-gray);
	border-radius: 8px;
	overflow: hidden;
	flex-wrap: wrap;
}
ul.sort-btn-group li {
    border-right: 1px solid var(--light-gray);
}
ul.sort-btn-group .btn {
    display: flex;
    align-items: center;
    min-width: 85px;
    justify-content: center;
    font-size: 0.875rem;
	border-radius: 0;
	color: var(--gray-dark) !important;
	border: none;
}
ul.sort-btn-group a.btn.active {
    background: var(--primary) !important;
    color: var(--white) !important;
}
.form-label {
    margin-bottom: .5rem;
    font-size: 1rem;
    color: var(--dark);
    font-weight: 300;
}
.form-control:focus {
    color: var(--gray-dark);
    background-color: var(--white);
    border-color: var(--form-border-color);
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(248,116,22,.25) !important;
}
.input-group.icon-form-rgt input.form-control {
    border-radius: 8px 0 0 8px !important;
	border-right: 0 !important;
}
.input-group.icon-form-lft input.form-control {
    border-radius: 0 8px 8px 0 !important;
	border-left: 0 !important;
}
span.input-group-text {
    background: none;
    border: 1px solid var(--form-border-color);
    border-radius: 8px;
}
.success {
	color: var(--success) !important;
}
.reject {
	color: var(--danger) !important;
}
*,
::after,
::before {
	box-sizing: border-box
}

/* switch-button */
.switch {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 15px;
    border-radius: 20px;
    background: #dfd9ea;
    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    cursor: pointer;
}
.switch::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -2px;
    width: 22px;
    height: 22px;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 50%;
    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.switch:active::before {
    box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);
}
input:checked + .switch {
    background: var(--light-primary);
}
input:checked + .switch::before {
    left: 22px;
    background: var(--primary);
}
input:checked + .switch:active::before {
    box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2);
}
/* switch-button */
.wrapper {
	display: flex
}
.wrapper .content-wrapper {
	background-color: var(--light);
	width: 100%;
	/* overflow-x: hidden */
}
.wrapper .content-wrapper .content {
    flex: 1 0 auto;
    padding-left: 15rem;
    background: var(--bg-color);
	height: 100%;
}
body.sidebar-toggled .wrapper .content-wrapper .content {
	padding-left: 6.875rem;
}
.scroll-to-top {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    display: none;
    width: 2.75rem;
    height: 2.75rem;
    text-align: center;
    color: var(--white) !important;
    background: rgb(248 116 23 / 48%);
    line-height: 46px;
}

.scroll-to-top:focus,
.scroll-to-top:hover {
	color: var(--white);
}

.scroll-to-top:hover {
	background: #5a5c69
}

.scroll-to-top i {
	font-weight: 800
}


/* .date-picker-input {
	position: relative;
	display: inline-block;
  }
  

  
  .calendar-icon {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	cursor: pointer;
  } */


  .disable-tag-click {
	cursor: default; /* sanjith */
  }

  .hide-logo {
	display: none;
  }




@-webkit-keyframes growIn {
	0% {
		transform: scale(.9);
		opacity: 0
	}

	100% {
		transform: scale(1);
		opacity: 1
	}
}

@keyframes growIn {
	0% {
		transform: scale(.9);
		opacity: 0
	}

	100% {
		transform: scale(1);
		opacity: 1
	}
}

.animated--grow-in,
.sidebar .nav-item .collapse {
	-webkit-animation-name: growIn;
	animation-name: growIn;
	-webkit-animation-duration: .2s;
	animation-duration: .2s;
	-webkit-animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
	animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1)
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0
	}

	100% {
		opacity: 1
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	100% {
		opacity: 1
	}
}

.animated--fade-in {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
	-webkit-animation-duration: .2s;
	animation-duration: .2s;
	-webkit-animation-timing-function: opacity cubic-bezier(0, 1, .4, 1);
	animation-timing-function: opacity cubic-bezier(0, 1, .4, 1)
}


.sidebar .nav-item .nav-link,
.topbar .nav-item .nav-link {
	position: relative
}

.sidebar .nav-item .nav-link .badge-counter,
.topbar .nav-item .nav-link .badge-counter {
	position: absolute;
	transform: scale(.7);
	transform-origin: top right;
	right: .25rem;
	margin-top: -.25rem
}

.topbar {
	height: 5.5rem;
	position: fixed !important;
	width: -webkit-fill-available;
	z-index: 99;
	display: flex !important;
	justify-content: space-between !important;
	padding: 10px 28px !important;
	background: var(--bg-color);
	width: -moz-available;
}

.topbar .navbar-search {
	width: 25rem
}

.sidebar {
    /* width: 6.5rem; */
    min-height: 100vh;
    position: fixed;
    z-index: 999;
    background: var(--white);
	border-right: 1px solid #f5f4f4;
}
.sidebar ul {
    padding-left: 10px;
    list-style: none;
}
.sidebar ul.side-menu {
    height: 100%;
    /* max-height: 600px; */
    overflow-y: auto;
	overflow-x: hidden;
}
.sidebar .nav-item {
	position: relative;
	list-style: none;
}
.sidebar li a.nav-link {
    display: flex;
    align-items: center;
	color: var(--gray) !important;
}
.sidebar li a.nav-link:hover {
    color: var(--primary) !important;
}
.sidebar .side-nav-item.active a.nav-link, .sidebar .side-nav-item.active .menu-accordion-sub-menu .side-nav-item.active a.nav-link{
    color: var(--primary) !important;
}
.sidebar .side-nav-item.active .menu-accordion-sub-menu a.nav-link {
    color: var(--gray) !important;
}
.sidebar li a.nav-link p {
	margin-bottom: 0;
	font-size: 0.906rem;
	/* font-size: 0.938rem; */
	font-weight: 500;
	margin-left: 10px;
	width: 175px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.sidebar a.nav-link.accordion-button p {
	width: auto;
}
.sidebar .accordion-button::after {
	width: 1rem;
	height: 1rem;
	background-size: 1rem;
}
.sidebar li a.nav-link span {
    width: 24px;
    height: 24px;
}
.sidebar .nav-item:last-child {
	margin-bottom: 1rem
}

.sidebar .nav-item .nav-link {
	/* text-align: center; */
	padding: .75rem 1rem;
}
.sidebar .accordion-button:focus {
    z-index: 3;
    border-color: none;
    outline: 0;
    box-shadow: none !important;
}
.sidebar .accordion-button:not(.collapsed) {
	background-color: #fff;
	box-shadow: none;
}
.sidebar .nav-item .collapse .collapse-inner {
	border-radius: .35rem;
	box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15)
}
.sidebar .nav-item .collapsing {
	display: none;
	transition: none
}

.sidebar .nav-item .collapse .collapse-inner,
.sidebar .nav-item .collapsing .collapse-inner {
	padding: .5rem 0;
	min-width: 10rem;
	font-size: .85rem;
	margin: 0 0 1rem 0
}

.sidebar .nav-item .collapse .collapse-inner .collapse-header,
.sidebar .nav-item .collapsing .collapse-inner .collapse-header {
	margin: 0;
	white-space: nowrap;
	padding: .5rem 1.5rem;
	text-transform: uppercase;
	font-weight: 800;
	font-size: .65rem;
	color: #b7b9cc
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
	padding: .5rem 1rem;
	margin: 0 .5rem;
	display: block;
	color: #3a3b45;
	text-decoration: none;
	border-radius: .35rem;
	white-space: nowrap
}

.sidebar.toggled {
	width: 0 !important;
	overflow: hidden
}
.sidebar.toggled .sidebar-card {
	display: none
}
.page {
    padding: 100px 15px 0 15px;
	margin-bottom: 50px;
}
ul.top-search {
    list-style: none;
    display: flex;
    justify-content: end;
}
ul.menu-accordion-sub-menu {
    padding-left: 0;
    margin: 0 15px 0 10px;
	background: var(--bg-light);
	border-radius: 10px;
}
form.navbar-search .input-group {
    background: var(--white);
    border: 1px solid var(--border-color) !important;
    border-radius: 8px;
	flex-wrap: nowrap;
}
form.navbar-search .input-group input.form-control {
	border: none !important;
	padding-left: 0;
}
form.navbar-search input {
    width: 250px !important;
}

.dropdown-menu {
	animation: 0.5s slideup;
  }
  
  @keyframes slideup {
	from {
	  transform: translateY(10%);
	}
  
	to {
	  transform: translateY(0);
	}
  }
  .btn-group.notification-sec .dropdown-toggle::after {
    display: none;
}
.btn-group.notification-sec a.btn.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
    border: 1px solid var(--border-color);
    border-radius: 8px;
	color: #727272 !important;
}

.btn-group.notification-sec ul.dropdown-menu.dropdown-menu-end {
    min-width: 400px;
}
.btn-group.notification-sec ul.dropdown-menu.dropdown-menu-end li {
	border-bottom: 1px solid var(--form-border-color);
}
ul li.notfctn-lst:hover {
    background: var(--bg-color);
}
.btn-group.notification-sec ul.dropdown-menu.dropdown-menu-end h5 {
    font-size: 1rem;
    margin-bottom: 0;
}
.btn-group.search-drpdwn .dropdown-toggle::after {
    display: none;
}
.btn-group.search-drpdwn a.btn.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    color: #727272 !important;
}
a.clear-all {
    font-size: 0.8125rem;
}
li.notfctn-lst img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
	object-fit: cover;
}
li.notfctn-lst h6 {
    font-size: 0.875rem;
    margin-bottom: 5px;
    font-weight: 600 !important;
	color: var(--primary);
}
li.notfctn-lst p {
    font-size: 0.813rem;
	margin-bottom: 0;
	color: var(--gray-dark);
}
li.notfctn-lst .time {
    font-size: 11px !important;
	margin-bottom: 0 !important;
	color: var(--gray) !important;
}
li.notfctn-lst i{
	color: var(--gray);
}
span.online-indctn {
    width: 10px;
    height: 10px;
    background: var(--success);
    border-radius: 50%;
    margin-right: 5px;
}
.profie-btn {
    display: flex;
    align-items: center;
}
.profie-btn img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #f87417;
	object-fit: cover;
}
.pfl-drpup {
    margin-left: 10px;
    text-align: left;
}
.profie-btn h5 {
    color: var(--dark);
    font-size: 1rem;
	margin-bottom: 0;
	width: 120px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.profie-btn span {
	color: var(--primary);
	font-size: 0.875rem;
	display: block;
}
.btn-group.header-profile .dropdown-toggle::after {
    display: none;
}
.btn-group.header-profile a.nav-link {
    color: var(--gray) !important;
}
.btn-group.header-profile a.nav-link:hover {
	background: var(--bg-light);
	color: var(--primary) !important;
}
a.sidebar-logo {
    padding: .5rem 1rem;
    display: flex;
    margin-bottom: 10px;
}
a.sidebar-logo img {
    height: 89px;
}
.navbar-nav.sidebar.toggled .nav-item a.nav-link p , .navbar-nav.sidebar.toggled .side-nav-item a.nav-link p  {
    display: none !important;
}
.navbar-nav.sidebar.toggled .nav-item .profie-btn img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
}
.navbar-nav.sidebar.toggled .nav-item .pfl-drpup {
    display: none !important;
}
.navbar-nav.sidebar.toggled .nav-item a.nav-link {
	justify-content: center;
}
.navbar-nav.sidebar.toggled a.sidebar-logo img {
    height: 70px;
}
.btn-group.search-drpdwn ul.dropdown-menu.dropdown-menu-end {
    background: none;
    padding: 0;
    border: none;
}
.form-control:focus {
	box-shadow: none !important;
}
.btn-link {
    font-weight: 400;
    color: var(--primary) !important;
    text-decoration: underline;
    font-size: 25px !important;
}
.btn-check:focus+.btn, .btn:focus {
	box-shadow: none !important;
}
.card {
	border: 1px solid var(--border-color) !important;
	border-radius: 20px !important;
}
@media (min-width:768px) {
	.sidebar {
		width: 15rem !important;
		transition: all .25s ease-in;
		overflow: hidden;
	}
	.sidebar .nav-item .collapse {
		position: relative;
		left: 0;
		z-index: 1;
		top: 0;
		-webkit-animation: none;
		animation: none
	}
	.sidebar .nav-link {
		display: block;
		width: auto;
		text-align: left;
		padding: .75rem;
		margin-right: 1rem;
		border-radius: 10px;
		min-width: 14rem;
	}
	li.toggle-item a.nav-link {
		background: #F5F5F5;
	}
	
	.sidebar.toggled {
		overflow: visible;
		width: 6.5rem !important;
		transition: all .25s ease-in;
	}
	body.sidebar-toggled .nav-link {
		min-width: auto;
	}
	body.sidebar-toggled .left-nav li a.nav-link {
		/* justify-content: center; */
		padding-left: 25px;
	}
	body.sidebar-toggled ul.collapse-menu li a.nav-link {
		justify-content: center !important;
		padding-left: 0;
	}
 }


 /* login-section */

section.login-pg {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 50px;
}
.login-lft-sec {
	background: url(./Assets/Images/login-bg.jpg) no-repeat;
    background-position: top;
    width: 100%;
    min-height: 750px;
    border-radius: 40px;
    overflow: hidden;
    background-size: cover;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: baseline;
}
.login-img-txt h3 {
    font-size: 2.875rem;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 0;
}
.login-img-txt p {
    font-size: 1.25rem;
    color: #fff;
	margin-top: 10px;
	margin-bottom: 0;
}
.login-form {
    min-height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.login-form button {
	width: 100%;
}
.login-form p {
	color: var(--gray);
}
.copy-rt-sec {
	text-align: center;
}
.copy-rt-sec p{
	margin-bottom: 0;
}
.copy-rt-sec p {
    margin-bottom: 0;
    font-size: .875rem;
	margin-top: 30px;
}
.otp-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.otp-input input.form-control {
    margin: 5px;
    max-width: 100px!important;
    width: 100%!important;
}
span.error {
    color: red;
    font-size: 12px;
}
button.btn.resend-btn {
    border: none;
    color: var(--primary);
    padding: 0 !important;
    height: auto;
	font-size: 0.875rem;
}
/* login-section */

/* dashboard-page */
.card-body.dash-counts p {
	font-size: 1.125rem;
	color: var(--dark) !important;
}
.card-body.dash-counts h5 {
	font-size: 2.188rem;
	color: var(--dark);
	margin-bottom: 0;
}
.card-body.dash-counts span {
	color: var(--gray);
	font-size: 0.875rem;
}
.card-body.dash-counts a {
	font-size: 0.875rem;
	display: block;
	margin-top: 20px;
}
.card-body.rcnt-subscrbs h3 {
    font-size: 1.25rem;
}
.sort-sec {
    display: flex;
    align-items: center;
    border: 1px solid #f87417;
    padding-left: 10px;
    border-radius: 8px;
	height: 50px;
}
.sort-sec p{
	/* width: 100%; */
	margin-bottom: 0;
	color: var(--gray);
	font-size: 0.875rem;
}
.sort-sec select.form-select {
    border: 0 !important;
    width: 130px;
    font-size: 0.875rem;
    height: 47px;
}
select.form-select option {
	padding: 8px;
}

.subcriber-sec {
    display: flex;
    align-items: center;
}
.subcriber-sec img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}
.subcriber-nme {
    margin-left: 10px;
}
.subcriber-nme p{
	font-size: 1rem;
	margin-bottom: 0;
	color: var(--dark);
	font-weight: 500;
}
.subcriber-nme span {
	color: var(--gray);
	font-size: 0.813rem;
}
.subcribe-list {
    padding: 10px;
    background: var(--white);
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.subcribe-list:hover {
	background: var(--bg-light);
}
span.status-tag {
    display: inline-flex;
    border: 1px solid var(--primary);
    color: var(--primary);
    padding: 2px 5px;
    min-width: 85px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
	line-height: 0;
	font-size: 0.813rem;
	min-height: 30px;
}
span.status-tag-active {
	color: var(--success) !important;
	border: 1px solid var(--success);
}
span.status-tag-pending {
	color: var(--darkblue) !important;
	border: 1px solid var(--darkblue);
}
span.status-tag-inactive {
	border-color: var(--danger) ;
	color: var(--danger) !important;
}
span.status-tag.status-tag-inactive.subscription-tag-pending {
    padding: 3px 15px;
    background: #ffe9e9;
}
.card-body.dash-counts.sbscrb-counts p {
    font-size: 0.938rem;
    color: var(--gray);
}
.card-body.dash-counts.sbscrb-counts  h5{
    color: var(--primary);
}
.card-body.dash-counts.sbscrb-counts span {
	font-size: 0.813rem;
}
span.not-indication {
    width: 9px;
    height: 9px;
    background: #de7171;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 15px;
}
.morris-hover.morris-default-style {
    max-width: 170px;
    font-size: 0.875rem;
	left: 20px;
	display: none !important;
}

/* dashboard-page */
.profile-sec img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
	object-fit: cover;
}

.list-table .profile-sec img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
	object-fit: cover;
}
.list-table  .profile-sec {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.activity-card .profile-sec img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
	object-fit: cover;
}
.activity-card  .profile-sec {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.activity-card p, .list-table p {
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 0;
    color: var(--gray-dark);
}
.btn-group.option-btn a.dropdown-item.success {
	color: var(--success) !important;
}
.btn-group.option-btn a.dropdown-item.reject {
	color: var(--danger) !important;
}
.list-table span {
	color: var(--gray);
	font-size: 0.813rem;
}
.btn-group.option-btn .dropdown-toggle::after {
    display: none;
}
.dropdown-menu {
    border-radius: 15px !important;
    border: 1px solid #f3f3f3 !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px !important;
}
.btn-group.option-btn a.dropdown-item, .btn-group.option-btn button.dropdown-item {
	color: var(--gray) !important;
	font-size: 0.875rem;
	padding: .25rem 1rem !important;
	margin: 3px 0;
}

.btn-group.option-btn a.dropdown-item i, .btn-group.option-btn button.dropdown-item i {
    width: 20px !important;
}
.btn-group.option-btn a.dropdown-item:hover {
	color: var(--primary) !important;
	font-size: 0.875rem;
	background: var(--bg-light);
}
.dropdown-item.active, .dropdown-item:active {
    background-color: var(--bg-light) !important;
}

input[type=checkbox] {
	position: relative;
	  border: 2px solid #F87416;
	  border-radius: 3px;
	  background: none;
	  cursor: pointer;
	  line-height: 0;
	  margin: 0 .6em 0 0;
	  outline: 0;
	  padding: 0 !important;
	  vertical-align: text-top;
	  height: 22px;
	  width: 22px;
	  -webkit-appearance: none;
  }
  
  input[type=checkbox]:hover {
	opacity: 1;
  }
  
  input[type=checkbox]:checked {
	background-color: #F87416;
	opacity: 1;
	color: #fff;
  }
  
  input[type=checkbox]:before {
	content: '';
	position: absolute;
	right: 50%;
	top: 50%;
	width: 6px;
	height: 10px;
	border: solid #ffffff;
	border-width: 0 2px 2px 0;
	margin: -1px -1px 0 -1px;
	transform: rotate(45deg) translate(-50%, -50%);
	z-index: 2;
  }
  .btn-group.option-btn a.btn.dropdown-toggle {
    display: flex;
    align-items: center;
}
.form-select:focus {
	box-shadow: none !important;
}
nav.breadcrumb-nav {
    display: flex;
    align-items: center;
	flex-wrap: wrap;
    font-size: 0.9375rem;
}
span.back-brdr {
    height: 20px;
    border-right: 2px solid var(--gray);
}
.breadcrumb span {
    color: var(--gray) !important;
}
.crte-bsness-top-banner {
    height: 220px;
    background: var(--primary);
    border-radius: 20px 20px 0 0;
	position: relative;
}
.crte-bsness-top-banner-dtls {
	width: 100%;
	height: 100%;
	background: rgba(253, 163, 99, 0.637);
	position: relative;
	border-radius: 20px 20px 0 0;
}
.crte-bsness-top-banner-dtls::before {
    content: '';
	background: url(./Assets/Images/business-bg.png) no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    left: 50%;
    top: 0;
    width: 500px;
    height: 220px;
    transform: translateX(-50%);
}
.crte-bsness-top-banner-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid #f87416;
	position: relative;
	margin-top: 20px;
}
.crte-bsness-top-banner-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.crte-bsness-top-banner-dtls h3 {
	position: relative;
	color: var(--white);
}
.crte-bsness-top-banner-dtls p {
	position: relative;
	color: var(--white);
}
a.img-cnrtl-btn {
    width: 35px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--dark);
    border-radius: 50%;
    color: var(--white) !important;
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 18px;
    line-height: 0;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.banner-btn {
	position: absolute;
	right: 10px;
	bottom: 10px;
}
span.agent-type {
    display: block;
	color: var(--primary);
}
img.cmpny-cover-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
}
.company-prfl {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    padding: 2px;
}
.company-prfl img {
	border-radius: 50%;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.btn-danger-outline {
	display: flex !important;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--danger) !important;
	color: var(--danger) !important;
	border-radius: 8px !important;
	width: 120px;
}
.btn-danger-outline:hover {
	background: var(--danger) !important;
	color: var(--white) !important;
}
.cmpny-dtls h1 {
    font-size: 1.75rem;
	color: var(--gray-dark);
}
.cmpny-dtls p {
	color: var(--gray);
}
p.join-dt span {
	color: var(--primary);
}
.cmpny-dtls-txt {
    color: var(--gray);
    font-size: 0.875rem;
}
.cmpny-dtls-txt p{
	color: var(--gray-dark);
	font-size: 1rem;
}
a.btn.whtsapp-btn {
    background: var(--success);
    color: var(--white) !important;
    display: flex;
    align-items: center;
    justify-content: center;
	width: 150px;
}
a.btn.whtsapp-btn i{
	font-size: 18px;
}
.cmpny-dtls-tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-color: #e5e5e5 !important;
    border-bottom-color: var(--white) !important;
    border-radius: 10px 10px 0 0;
    color: var(--primary) !important;
    font-size: 1rem !important;
}
.cmpny-dtls-tabs .nav-item button.nav-link {
	min-width: 150px;
	font-size: 0.875rem;
	color: var(--gray);
	height: 41px;
}
.cmpny-dtls-tabs .tab-content {
    min-height: 500px;
    background: var(--white);
    padding: 15px;
    border: 1px solid var(--border-color);
	margin-top: -1px;
	border-radius: 0 0 20px 20px;
}
.cmpny-dtls-tabs .nav-tabs {
    border-bottom: 1px solid var(--white);
    background: var(--light-gray);
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y:visible;
    scroll-behavior: auto;
}
.cmpny-dtls-tabs .nav-tabs::-webkit-scrollbar {
    display: none;
}
.card.ovrview-cards {
    background: #f87416;
    color: #fff !important;
}
.card.ovrview-cards h3 {
	color: var(--white);
	margin-bottom: 0;
}
.card.ovrview-cards span {
	font-size: 0.813rem;
}
.follow-card {
	background: var(--purple) !important;
}
.profile-card {
	background: var(--darkblue) !important;
}
.whatsapp-card {
	background: var(--success) !important;
}
.call-card {
	background: var(--primary) !important;
}
.card.ovrview-cards.account-card {
    background: var(--success);
}
.card.ovrview-cards.commission-card {
	background: #6A66F6;
}
.service-list {
    display: flex;
    align-items: center;
}
.service-list img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    object-fit: cover;
}
.service-list p{
	color: var(--gray-dark);
	font-size: 1rem;
	margin-bottom: 0;
	margin-left: 10px;
}
.subcribe-list.service-list:hover .option-sec-hover {
	display: block;
}
.option-sec-hover {
	display: none;
}
.sz-bg-img img {
	width: 200px !important;
}
img.offer-banner {
    width: 100%;
    border-radius: 20px 20px 0 0;
    position: relative;
    height: 150px;
    object-fit: cover;
}
span.offer-tag {
    border: 1px solid var(--gray);
    color: var(--gray);
    padding: 5px 10px;
    font-size: 0.813rem;
    border-radius: 20px;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
	line-height: 0;
    height: 30px;
}
span.usercat-tag {
	border: 1px solid var(--gray);
	color: var(--gray);
	padding: 5px 10px;
	font-size: 0.813rem;
	border-radius: 20px;
	display: flex;

	align-items: center;
	justify-content: center;
}

span.usercat-tag.approved-tag {
	background: var(--success);
	border: 1px solid var(--success);
	color: var(--white);
}

span.usercat-tag.deactive-tag {
	background: var(--danger);
	border: 1px solid var(--danger);
	color: var(--white);
}


span.offer-tag.approved-tag {
	background: var(--success);
	border: 1px solid var(--success);
	color: var(--white);
}
span.offer-tag.premium-tag {
	background: var(--primary);
	color: var(--white);
	border: 1px solid var(--primary);
}
span.offer-tag.pending-tag {
	background: var(--darkblue);
	border: 1px solid var(--darkblue);
	color: var(--white);
}
span.offer-tag.rejected-tag {
	background: var(--danger);
	border: 1px solid var(--danger);
	color: var(--white);
}
.offer-optn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 0;
    padding-left: 10px;
}

img.offer-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid var(--primary);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
	object-fit: cover;
}
.offers-card-hd a.btn.dropdown-toggle {
    background: rgb(255 229 210);
    width: 30px;
    height: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50%;
    margin: 0 10px;
}
.img-sec img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
	object-fit: cover;
}
.offer-img-sec .img-sec {
    border: 1px solid var(--border-color);
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 10px;
}
.card-body.offer-dtls span {
    color: var(--primary);
    font-size: 0.875rem;
    margin-bottom: 5px;
    display: block;
}
.card-body.offer-dtls p {
	font-size: 0.938rem;
	color: var(--gray-dark);
}
.card-body.offer-dtls ul li {
	font-size: 0.875rem;
}
.tab-service-sec {
    width: 100%;
}
.services-imgs {
    text-align: center;
    position: relative;
}
.services-imgs img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 20px;
}
a.close-btn {
    font-size: 20px;
    color: var(--primary);
    position: absolute;
    right: 8px;
    top: 8px;
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tab-service-sec.gallery-sec .services-imgs img {
    width: 100%;
    height: 220px;
}
.modal-content {
	border-radius: 20px;
}
.upload-img {
    padding: 20px 10px;
    text-align: center;
    background: var(--bg-light);
    border: 1px dashed var(--form-border-color);
    border-radius: 10px;
}
/* star-rating */

.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color: var(--secondary);
	margin-right: 10px;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: var(--warning);    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: var(--warning);  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}
/* star-rating */
.modal-footer {
	border-top: none;
}

.overview-edit-service-img {
    border-radius: 20px;
    position: relative;
}
.overview-edit-service-img img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 15px;
    border: 1px dashed var(--primary);
}
a.edit-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: var(--primary);
    color: var(--white) !important;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* scroll-tab-start */

.scrtabs-tab-container * {
	box-sizing: border-box; }
  
	.scrtabs-tab-container {
		height: 42px;
		background: #ededed;
		border-radius: 10px 10px 0 0;
	}
	.scrtabs-tab-container .tab-content {
	  clear: left; }
  
  .scrtabs-tab-container .scrtabs-tabs-movable-container > .navbar-nav {
	-ms-flex-direction: row;
	flex-direction: row; }
  
  .scrtabs-tabs-fixed-container {
	float: left;
	height: 42px;
	overflow: hidden;
	width: 100%; }
  
  .scrtabs-tabs-movable-container {
	position: relative;
	transition: transform 2s; }
	.scrtabs-tabs-movable-container .tab-content {
	  display: none; }
  
  .scrtabs-tab-container.scrtabs-rtl .scrtabs-tabs-movable-container > ul.nav-tabs {
	padding-right: 0; }
  
  .scrtabs-tab-scroll-arrow {
	cursor: pointer;
	display: none;
	float: left;
	font-size: 12px;
	height: 100%;
	padding-left: 0.6em;
	padding-top: 1.3em;
	width: 20px; 
	}
	.scrtabs-tab-scroll-arrow:hover {
	  background-color: var(--bg-light); }
  
  .scrtabs-tab-scroll-arrow > span {
	border-right: 3px solid var(--primary);
	border-bottom: 3px solid var(--primary);
	display: block;
	width: 8px;
	height: 8px;
	transform: rotate(-45deg); }
	.scrtabs-tab-scroll-arrow > span:hover {
	  border-right-color: var(--primary);
	  border-bottom-color: var(--primary); }
  
  .scrtabs-tab-scroll-arrow-left > span {
	transform: rotate(135deg); }
  .scrtabs-tab-scroll-arrow-right {
	padding-left: 0.4em; }
  
  .scrtabs-tab-scroll-arrow,
  .scrtabs-tab-scroll-arrow .scrtabs-click-target {
	cursor: pointer; }
  
  .scrtabs-tab-scroll-arrow.scrtabs-with-click-target {
	cursor: default; }

	.break  {
		pointer-events: none;
		color: gray; 
		text-decoration: none; 
		cursor: not-allowed; 
	  }
  
  .scrtabs-tab-scroll-arrow.scrtabs-disable,
  .scrtabs-tab-scroll-arrow.scrtabs-disable .scrtabs-click-target {
	color: #ddd;
	cursor: default; }
	.scrtabs-tab-scroll-arrow.scrtabs-disable > span,
	.scrtabs-tab-scroll-arrow.scrtabs-disable .scrtabs-click-target > span {
	  border-color: var(--gray); }
  
  .scrtabs-tab-scroll-arrow.scrtabs-disable:hover {
	background-color: initial; }

/* scroll-tab-end */
.img-drpdown {
    position: absolute;
    top: 5px;
    right: 5px;
}
.img-drpdown .btn-group.option-btn a.btn.dropdown-toggle {
    background: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
span.star-rating {
    color: var(--warning);
    font-size: 0.938rem;
}
.list-table {
	overflow: auto !important;
	min-height: 300px;
}

 .list-table div:first-child {
	overflow: visible !important;
	/* min-width: auto !important; */
}
.list-table div:nth-child(2) {
	overflow: visible !important;
	/* min-width: auto !important; */
} 

 /* .data-table-sec {
    min-width: 250px;
}  */
a.img-cnrtl-btn input {
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 99;
	cursor: pointer;
}
a.img-cnrtl-btn span {
	position: absolute;
}
.datatable-hd h6 {
    color: var(--primary);
    font-size: 0.875rem;
    font-weight: 500;
	margin-bottom: 0;
}
.rdt_TableCell:last-child {
	justify-content: end;
}
.acnt-bal-sec {
    background: var(--bg-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
	color: var(--primary);
	flex-wrap: wrap;
}
.acnt-bal-sec h5 {
	color: var(--primary);
}
.sort-sec.date-sort p {
    font-size: 1rem;
}
.sort-sec.date-sort select.form-select {
    min-width: 200px;
}
.category-card {
    border: 1px solid var(--border-color);
    border-radius: 20px;
}
.category-card .card {
	border: 0 !important;
}
.category-card img {
    border-radius: 20px 20px 0 0;
    height: 200px;
    object-fit: cover;
}
.category-card .btn-group.option-btn a.btn.dropdown-toggle {
    width: 25px;
    height: 25px;
    background: var(--bg-light);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-img img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 220px;
    object-fit: cover;
    object-position: top;
    border-radius: 20px 20px 0 0;
}
.hrs-weekdays {
    height: 56px;
    display: flex;
    align-items: center;
}
a.remove-btn {
    color: var(--danger);
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: var(--bg-color);
	display: flex;
	align-items: center;
	justify-content: center;
}
a.remove-btn:hover {
	color: var(--white) !important;
	background: var(--danger);
}
.truncate-name p {
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
a.btn.btn-success-outline {
	border: 1px solid var(--success);
	color: var(--success) !important;
	border-radius: 5px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}
a.btn.btn-success-outline:hover {
	border: 1px solid var(--success);
	color: var(--white) !important;
	background: var(--success);
}
span.dlt-icon {
	color: #ff5328;
}
 
label.hrs-label {
    display: none;
}
.services-imgs.modal-category-img {
    position: relative;
	height: 150px;
	width: 150px;
	/* margin: 0 auto; */
}
.services-imgs.modal-category-img img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}
img.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
}
a.back-btn {
	font-size: 22px;
}
a.banner-btn.banner-dlt-btn {
    background: var(--dark);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--danger) !important;
    border-radius: 50%;
}
.parentnav span{
	color: var(--primary) !important;
	}
	
	.parentnav p{
	color: var(--primary) !important;
	}
	span.offer-tag.deactive-tag {
		background: var(--danger);
		border: 1px solid var(--danger);
		color:  var(--white);
	}
	.cmpny-dtls-tabs .rts___btn {
		cursor: pointer;
		background-color: transparent;
		border-radius: 15px 15px 0 0;
		box-shadow: none;
		color: var(--dark);
		border: 0;
	}
	.cmpny-dtls-tabs .rts___btn button {
		font-size: 0.875rem;
	}
	.cmpny-dtls-tabs .rts___tabs {
		display: flex;
		align-items: center;
		overflow-y: hidden;
	}
	.rts___nav___btn___container .rts___btn {
		border: 0;
		width: 23px;
		height: 100%;
		border-radius: 0;
		background: #e3e3e3;
	}
	.rts___tab___selected {
		background-color: var(--white)  !important;
		color: var(--primary) !important;
		box-shadow: #00000029 0 1px 4px;
	}
	.rts___nav___btn:hover {
		background-color: var(--light-primary) !important;
		transition: .5s all;
	}


	/* sanjith */

	
	  /* end */

	.pagination {
		display: flex;
		justify-content: end;
		align-items: center;
	}
	.pagination ul {
		display: contents;
		list-style: none;
		margin-top: 20px;
	}
	.pagination ul li {
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #efefef;
		margin: 0 3px;
		color: #333 !important;
		font-size: 0.875rem !important;
		border-radius: 5px;
	}
	.pagination ul li a {
		color: var(--dark) !important;
		font-size: 0.875rem !important;
	}
	.pagination ul li.selected {
		background: var(--primary);
	}
	.pagination ul li.selected a{
		color: var(--white) !important;
	}
	.reports-nav {
		width: 175px;
		display: flex;
		align-items: center;
	}
	span.down-arrow {
		color: var(--gray-dark) !important;
		animation: ease-in-out;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	a.nav-link.collapsed span.down-arrow {
		transform: rotate(180deg);
	}
	
	ul.collapse-menu {
		background: #fef3ec;
		margin-right: 15px;
		padding-left: 0;
		border-radius: 10px;
		margin-left: 15px;
	}
	.list-table {
		overflow: auto !important;
		min-height: 300px;
	}
	.modal-content {
		border-radius: 20px !important;
	}
	p.description-sec span {
		display: initial !important;
		color: var(--gray-dark) !important;
	}
	a.read-more {
		font-size: 0.875rem;
	}
	.card.offers-card {
		height: 100%;
	}
	span.date-icon {
		position: absolute;
		z-index: 9;
		right: 10px;
		top: 10px;
		color: var(--gray) !important;
	}
	.carousel .carousel-status{
		display: none;
	}
	ul.thumbs.animated {
		padding-left: 0;
	}
	li.thumb.selected {
		border-color: var(--primary) !important;
	}
	li.slide {
		height: 300px;
		width: 100%;
	}
	li.slide.selected img {
		height: 300px;
		object-fit: cover;
		vertical-align: unset !important;
	}
  /* ---- previous/next buttons ---- */
  
  .flickity-prev-next-button {
	top: 50%;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	/* vertically center */
	transform: translateY(-50%);
  }
  
  .flickity-prev-next-button.previous {
	left: 10px;
  }
  .flickity-prev-next-button.next {
	right: 10px;
  }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
	left: auto;
	right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
	right: auto;
	left: 10px;
  }
  
  .flickity-prev-next-button .flickity-button-icon {
	position: absolute;
	left: 20%;
	top: 20%;
	width: 60%;
	height: 60%;
  }
  
  /* ---- page dots ---- */
  
  .flickity-page-dots {
	position: absolute;
	width: 100%;
	bottom: -25px;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	line-height: 1;
  }
  
  .flickity-rtl .flickity-page-dots {
	direction: rtl;
  }
  
  .flickity-page-dots .dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 0 8px;
	background: #333;
	border-radius: 50%;
	opacity: 0.25;
	cursor: pointer;
  }
  
  .flickity-page-dots .dot.is-selected {
	opacity: 1;
  }
  .activity-log-date {
	position: relative;
  }
  .activity-log-date span.error {
    position: absolute;
    left: 0;
}
.rdt_TableCell:last-child div:first-child {
    white-space:normal !important;
    overflow: visible !important;
    text-overflow:unset !important;
}
.list-table.table-responsive div:first-child {
    white-space:normal !important;
    overflow: visible !important;
    text-overflow:unset !important;
}
p.credit-amt, p.credit-amt span {
    color: var(--success) !important;
}
p.debit-amt, p.debit-amt span {
    color: var(--danger) !important;
}
.search-no {
    background: #fef8f4;
    padding: 5px;
    min-width: 30px;
    height: 30px !important;
    color: #f87417 !important;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem !important;
    border: 1px solid #ffe2cd;
    line-height: 0 !important;
}
.rdt_TableCol:last-child {
    text-align: end;
    justify-content: end;
}
.modal-body {
	padding: 3rem !important;
}
.skip-crop-btns .btn {
    height: 40px !important;
    font-size: 14px;
}
.modal-loader img {
	transform: translateX(-50%);
}
.modal-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: rgb(255 255 255 / 65%);
    border-radius: 20px;
}
ul.notification-list {
    list-style: none;
    padding-left: 0;
}
ul.notification-list li {
    border-bottom: 1px dashed var(--form-border-color);
}
li.notf-read {
    background: #fffbf9;
    opacity: 0.9;
}
li.notf-read p {
    color: var(--gray);
}
.ReactCrop__child-wrapper {
    max-height: 400px !important;
}
.list-table.search-reports-list .rdt_TableCol:last-child {
    text-align: start;
    justify-content:flex-start ;
}
.list-table.search-reports-list .rdt_TableCell:last-child {
	text-align: start;
    justify-content: flex-start ;
}
.thumb-img {
	width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}
p.over-due {
    font-size: 12px;
    color: var(--danger);
    font-weight: 600;
}
.sidebar li a.nav-link span.search-no {
    min-width: 22px !important;
    height: 22px !important;
    width: auto !important;
}
.review-popup h6 {
	color: var(--primary);
}

.review-popup p {
	color: var(--gray-dark);
	font-size: 0.875rem;
}
.review-popup span.date{
	color: var(--gray) !important;
	font-size: 0.75rem;
	display: block;
}
p.no-notf-records {
    width: auto;
    margin: 15px;
    text-align: center;
    background: var(--bg-color);
    padding: 5px;
    font-size: 0.75rem;
    color: var(--gray);
    border-radius: 5px;
	border: 1px solid var(--form-border-color);
}
.banner-cndnt {
    height: 100%;
    width: 100%;
    background: rgb(178 74 0 / 48%);
    position: relative;
    border-radius: 18px 18px 0 0;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: #ffe3c9 !important;
    border-radius: 0 0 10px 10px !important;
}
.ck.ck-toolbar {
	border-color: #ffe3c9 !important;
    border-radius: 10px 10px 0 0 !important;
}
span.expired-tag {
    color: var(--danger);
    border: 1px solid #ff8282;
    background: #ffd7d7;
}
.sidebar.toggled .reports-nav {
    width: auto;
}
body.sidebar-toggled ul.collapse-menu .nav-link {
    margin-right: 0;
}
.sidebar.toggled ul.collapse-menu {
	margin-left: 0;
	margin-right: 10px;
}
.rdt_TableCell {
    min-width: 150px !important;
}
.gallery-modal .slick-slide img {
    display: block;
    height: 500px;
    width: 100%;
    object-fit: contain;
}
.gallery-modal .slick-prev, .gallery-modal .slick-next {
	width: 40px !important;
	height: 40px !important;
	z-index: 99;
}
.gallery-modal .slick-prev:before, .slick-next:before {
    font-size: 40px !important;
    color: #f87416 !important;
}
.gallery-modal .slick-next {
    right: 0 !important;
}
.gallery-modal .slick-prev {
    left: 0 !important;
}
.gallery-modal .slick-dots {
	bottom: -25px !important;
}
.gallery-modal .slick-dots .rts___btn {
	bottom: 15px !important;
}
.gallery-modal .slick-dots li button:before {
	color: var(--gray) !important;
	opacity: 1 !important;
	font-size: 10px !important;
}
.gallery-modal .slick-dots li.slick-active button:before {
	color: var(--primary) !important;
}
.modal.fade.show {
	background: rgb(51 51 51 / 54%) !important;
}
.gallery-modal a.modal-close {
    font-size: 25px;
    margin-bottom: 0;
    top: 15px;
    position: absolute;
    right: 20px;
    font-weight: 300 !important;
    color: var(--danger) !important;
}
a.nav-close {
    background: var(--primary) !important;
    width: 30px;
    height: 30px;
    color: var(--white) !important;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    right: -15px;
    top: 5px;
    border: 2px solid #fff;
}
.dwnld-lodr {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffffb3;
    top: 0;
    left: 0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}
fieldset.MuiOutlinedInput-notchedOutline {
	border: 1px solid var(--form-border-color);
	border-radius: 8px !important;
}
/* .rdt_Table {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
} */
/* .rdt_Table:last-child {
    border-bottom: 1px solid rgba(149, 4, 4, 0.796);
} */
nav.rdt_Pagination {
    border-top: none;
}
.modal-header {
    border-bottom: 0 !important;
}
button.btn-close {
    top: 20px !important;
    right: 20px !important;
}
.popup-img {
    cursor: pointer;
}
span.MuiRating-icon.MuiRating-iconFilled {
    color: #faaf00;
}
span.MuiRating-icon.MuiRating-iconEmpty {
    color: #a7a7a7;
}
.tab-service-sec.gallery-sec .services-imgs video {
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-radius: 20px;
}
.gallery-modal .slick-slide video {
    display: block;
    width: 100%;
    object-fit: contain;
	height: 500px;
}
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex !important;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
}
.services-imgs.modal-category-img video {
    width: 100%;
    height: 150px;
    object-fit: cover;
	border-radius: 10px;
}
span.play-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: var(--light-primary);
}
.overview-sec .carousel .thumbs-wrapper {
   display: none;
}
.carousel .control-prev.control-arrow:before {
    border-right: 8px solid var(--primary) !important;
}
.carousel .control-next.control-arrow:before {
    border-left: 8px solid var(--primary) !important;
}
.carousel .control-dots .dot {
    transition: opacity .25s ease-in;
    opacity: .3;
    box-shadow: 1px 1px 2px rgb(193 193 193 / 90%) !important;
    background: #f9833c !important;
}
.overview-sec ul.slider.animated img, .overview-sec ul.slider.animated video {
    height: 300px !important;
    object-fit: cover !important;
    vertical-align: unset !important;
	width: 100% !important;
}
p.tab-hd-p {
    font-size: 14px;
}
li.slide.selected video {
    width: 100%;
}
.profile-sec.file-thumb img {
    border-radius: 5px;
	border: 1px solid var(--light-gray);
}
.gallery-modal img {
    width: 100%;
    max-height: 500px;
    object-fit: contain;
    display: block;
}
.review-rating {
    display: flex;
    align-items: center;
}
.review-rating p {
    margin-bottom: 0;
    background: #4f88ef;
    color: #fff;
    border-radius: 10px;
    font-size: 12px;
    width: 40px;
    height: 23px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
}
.rdt_TableRow {
    padding: 10px 0;
}
/* *****--------------------------------------Reponsive View--------------------------------------------*********** */


@media (max-width:1399px) {
	
}
@media (max-width:1299px) {
	.login-lft-sec {
		min-height: 750px;
	}
	.login-form {
		min-height: 700px;
	}
}
@media (max-width:1199px) {
	.login-lft-sec {
		min-height: 500px;
	}
	.login-lft-sec img {
		width: 85px;
	}
	.login-img-txt h3 {
		font-size: 1.875rem;
	}
	.login-img-txt p {
		font-size: 1rem;
	}
	.login-form {
		min-height: 450px;
	}
	.rdt_TableCell {
		min-width: 180px !important;
	}
}
@media (max-width:991px) {
	section.login-pg {
		padding: 30px;
	}
	.login-lft-sec {
		padding: 20px;
	}
	.login-img-txt h3 {
		font-size: 1.688rem;
	}
	.login-img-txt p {
		font-size: 0.875rem;
	}
	.login-form p {
		font-size: 0.875rem;
	}
	.btn-group.search-drpdwn .dropdown-menu {
		background: transparent !important;
		padding: 0 !important;
		border: none !important;
		box-shadow: none !important;
		margin: 0;
		min-width: auto !important;
		z-index: 9;
	}
	/* .data-table-sec {
		min-width: 170px;
	} */
	.acnt-bal-sec {
		display: block;
		text-align: center;
	}
	.hours-hd {
		display: none;
	}
	label.hrs-label {
		display: block;
		font-size: 0.875rem;
		margin-bottom: 5px;
		font-weight: 300;
	}
	.btn-group.notification-sec ul.dropdown-menu.dropdown-menu-end {
		min-width: 300px;
	}
	.rts___tabs___container {
		overflow: hidden !important;
	}
	.cmpny-dtls-tabs .rts___btn {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	p {font-size: 0.875rem;}
	.form-label {font-size: 0.875rem;}
}
@media (max-height: 825px) {
	.login-lft-sec {
		min-height: 600px;
		max-height: 600px;
	}
	.login-form {
		min-height: 600px;
		max-height: 600px;
	}
  }

@media (max-width:767px) {
	.wrapper .content-wrapper .content {
		padding-left: 5.875rem;
	}
	body.sidebar-toggled .wrapper .content-wrapper .content {
		padding-left: 0px;
	}
	/* .sidebar .nav-item a.nav-link p, .sidebar .side-nav-item a.nav-link p {
		display: none !important;
	} */
	.pfl-drpup {
		display: none;
	}
	a.sidebar-logo img {
		height: 60px;
	}
	.profie-btn img {
		width: 40px;
		height: 40px;
	}
	.sidebar .nav-item a.nav-link, .sidebar .side-nav-item a.nav-link {
		/* width: 4.375rem; */
		width: auto;
		padding-left: 15px;
	}
	.sidebar .accordion-button::after {
		margin-left: inherit;
	}
	section.login-pg {
		height: 100%;
	}
	.login-lft-sec {
		min-height: 400px;
		border-radius: 20px;
	}
	.login-form {
		min-height: auto !important;
	}
	.top-login a {
		margin-top: 20px;
		display: block;
	}
	.login-lft-sec img {
		width: 4.688rem;
	}
	.list-table td:before {
		font-size: 0.875rem;
	}
	.banner-btn span {
		display: none;
	}
	a.btn.banner-btn {
		width: 40px;
		height: 40px;
	}
	.reports-nav {
		width: auto;
	}
	ul.collapse-menu {
		margin-left: 0;
		margin-right: 10px;
	}
	ul.collapse-menu .nav-link {
		margin-right: 0;
	}
	.modal-body {
		padding: 1rem!important;
	}
	body.sidebar-toggled .left-nav li a.nav-link {
		/* justify-content: center; */
		padding-left: 25px;
	}
	body.sidebar-toggled ul.collapse-menu li a.nav-link {
		justify-content: center !important;
		padding-left: 0;
	}
	a.nav-close {
		display: flex;
	}
	.wrapper .content-wrapper .content {
		padding-left: 0;
	}
	.sidebar {
		width: 14.5rem;
	}
	.sidebar li a.nav-link p {
		width: 135px;
	}
}
@media (max-width:576px) {
	.login-lft-sec {
		min-height: 300px;
	}
	.login-img-txt h3 {
		font-size: 1.25rem;
	}
	.page {
		padding: 100px 10px 0 10px;
	}
	.topbar {
		padding: 10px !important;
	}
	
	.crte-bsness-top-banner-dtls::before {
		display: none;
	}
	nav.breadcrumb-nav {
		font-size: 0.813rem;
	}
	ul.sort-btn-group li {
		width: 100%;
	}
	.service-list img {
		width: 45px;
		height: 45px;
	}
	.service-list p {
		font-size: 0.875rem;
	}
	.img-sec img {
		width: 45px;
		height: 45px;
	}
	img.loader {
		transform: translate(-50%);
	}
	.btn-group.search-drpdwn a.btn.dropdown-toggle {
		width: 40px;
		height: 40px;
		padding: 5px;
	}
}
@media (max-width:400px) {
	.btn-group.notification-sec ul.dropdown-menu.dropdown-menu-end {
		right: -100%;
	}
	.login-lft-sec img {
		width: 3rem;
	}
	.btn-group.header-profile a.btn.dropdown-toggle {
		padding-left: 0;
		padding-right: 0;
	}
	nav.navbar.navbar-expand.navbar-light.topbar.static-top h1 {
		font-size: 1.25rem;
	}
	
}
@media (max-height:900px) {
	.sidebar ul.side-menu {
		max-height: 550px;
	}
}


/*** added by lekshmi for datable***/

.rdt_TableHead {
    color: var(--primary) !important;
    font-size: 0.938rem !important;
    font-weight: 500 !important;
}